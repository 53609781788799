import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Projects from "../components/Projects";
import Seo from "../components/Seo";
// ...GatsbyImageSharpFluid

const ProjectPage = ({ data }) => {
  const { allDatoCmsProjectPage } = data;
  const projectsData = allDatoCmsProjectPage.edges[0].node.projects;

  return (
    <Layout>
      <Seo
        title="Projects"
        description="information about projects created by Belle Carre"
      />
      <main className="projects-page">
        <Projects projects={projectsData} titleText="all projects" />
      </main>
    </Layout>
  );
};
export const query = graphql`
  {
    allDatoCmsProjectPage {
      edges {
        node {
          title
          slug
          projects {
            title
            id
            stack {
              stack
            }
            siteUrl
            githubUrl
            desc
            featured
            image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default ProjectPage;
